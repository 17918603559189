import React,{ useEffect, useState } from 'react';
import axios from '../_config/axios';
import FontAwesome from 'react-fontawesome';
import { Grid } from 'react-bootstrap';
import { isAuthenticate } from '../_helper/authentication';
import Header from './header/Header';
import Sidebar from './sidebar/Sidebar';
import Footer from './Footer';
import Modal from 'react-modal';
import './sidebar/Modal.css'
import Swal from 'sweetalert2';

const Template = ({ children }) => {
    const [show, setShow] = React.useState(false);
    const [ msisdnError , setMsisdnError ] = useState(false);
    const [ otpError , setOtpError ] = useState("")
    const [ wrongMsisdnMessage , setWrongMsisdnMessage ] = useState("");
    const [ wrongOtpMessage , setWrongOtpMessage ] = useState("")
    const [ msisdn , setMsisdn ] = useState("");
    const [ otp , setOtp ] = useState("");
    const [ modalOpen , setModalOpen ] = useState(false);
    const [ otpModalOpen , setOtpModalOpen ] = useState(false);

    const showSidebar = () => {
        setShow(true);
        document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    }
    const hideSidebar = () => {
        setShow(false);
        document.getElementsByTagName('html')[0].style.overflowY = 'scroll';
    }
    const msisdnModal = () => 
    {
        setModalOpen(true);
    }
    const handleChange = (e) => {
        setMsisdn(e.target.value);
    }
    const handleOtpChange = (e) => {
        setOtp(e.target.value);
    }
    const submitHandler = () => {
        axios.get(`/subscription/request?msisdn=${msisdn}&service=daily`)
        .then((res)=>{
            if(res.data.data.result===true) {
                console.log("Success in subscription");
                setModalOpen(false);
                setOtpModalOpen(true);
            }
            else{
                console.log(("Failed in subscription"));
                setMsisdnError(true);
                setWrongMsisdnMessage(res.data.data.message);
            }
        })
        .catch((err)=>console.log(err));
    }
    const otpHandler = () => {
        axios.get(`/subscription/confirm?msisdn=${msisdn}&pin=${otp}&service=daily`)
        .then((res)=>{
            if(res.data.data.result===true) {
                console.log("Success in otp");
                setOtpModalOpen(false);
                Swal.fire({
                    type: 'success',
                    title: 'You are successfully subscribed'
                });
                hideSidebar();
                history.push('/');
            }
            else{
                console.log(("Failed in otp"));
                setOtpError(true);
                setWrongOtpMessage(res.data.data.message);
                // setOtpModalOpen(false);
                hideSidebar();
                history.push('/');
            }
        })
        setModalOpen(false);
        setOtpModalOpen(true);
    }
    const closesubscriptionmenu = () => (
        <FontAwesome
        name="times"
        onClick={()=>{setModalOpen(false); setMessage(!message)}}
        style={{
          color: '#000000',
          padding: '10px',
          cursor: 'pointer',
          backgroundColor: 'transparent',
          border: 0,
          position: 'absolute',
          top: '0.3rem',
          right: '0.5rem',
        }}
        />
      );
      const closeotpmenu = () => (
        <FontAwesome
        name="times"
        onClick={()=>setOtpModalOpen(false)}
        style={{
          color: '#000000',
          padding: '10px',
          cursor: 'pointer',
          backgroundColor: 'transparent',
          border: 0,
          position: 'absolute',
          top: '0.3rem',
          right: '0.5rem',
        }}
        />
      );
    var modalStyles = {overlay: {zIndex: 9999},content: {top: "200px",borderRadius:"16px",height:"230px"}};
    return (
        <React.Fragment>
            <Header openSideBar={showSidebar} isAuthenticate={Boolean(isAuthenticate())} />
            <div className="clearfix"></div>
            <Sidebar open={show} closeSideBar={hideSidebar} msisdnModal={msisdnModal} />
            <Grid fluid={true} className="page-content">{children}</Grid>
            <Modal isOpen={modalOpen} style={ modalStyles }>
               <div style={{ display:"flex" , flexDirection: "column"}}>
                {closesubscriptionmenu()}
                {/* { closeicon() } */}
                {/* {props.children} */}
                <div style={{marginTop:25,marginLeft:"65px",fontSize:"19px"}}>Enter Msisdn</div><br/>
                  <div style={{height:"50px"}}>
                    <input type='text' style={{textAlign:'center',borderRadius:81,marginBottom:"10px",marginLeft:"32px"}} onChange={handleChange} placeholder='96XXXXXXX'></input>
                    {
                       msisdnError && <div style={{marginLeft:"52px",color:"red",textTransform:"capitalize"}}>{wrongMsisdnMessage}</div>
                    }
                  </div>
                  {/* {checkMsisdn && <div style={{color:'green'}}>Phone number already subcscribe</div> } */}

                  <button style={{marginTop:10,backgroundColor:'red',marginLeft:"52px",marginRight:"52px",fontSize:"17px"}} onClick={submitHandler} >Submit</button>
                 
                </div>
            </Modal>
            <Modal isOpen={otpModalOpen} style={ modalStyles }>
               <div style={{ display:"flex" , flexDirection: "column"}}>
                {closeotpmenu()}
                {/* { closeicon() } */}
                {/* {props.children} */}
                <div style={{marginTop:25,marginLeft:"77px"}}>Enter OTP</div><br/>
                  <input type='text' style={{textAlign:'center',borderRadius:81,marginBottom:"10px"}} onChange={handleOtpChange} placeholder='XXXX'></input>
                  {
                       otpError && <div style={{marginLeft:"52px",color:"red",textTransform:"capitalize"}}>{wrongOtpMessage}</div>
                  }
                  {/* {checkMsisdn && <div style={{color:'green'}}>Phone number already subcscribe</div> } */}

                  <button style={{marginTop:10,backgroundColor:'red',marginLeft:"52px",marginRight:"52px",fontSize:"17px"}} onClick={otpHandler} >Submit</button>

                </div>
            </Modal>
            <Footer />
        </React.Fragment>
    );
};

export default Template;
